import {
  Button,
  Tooltip,
  Modal
} from 'bootstrap';

// Make available globally
window.Button = Button;
window.Tooltip = Tooltip;
window.Modal = Modal;
